import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Layout, notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { AdminForm } from '../../components/forms';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditUser = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const {
    match: {
      params: { id }
    }
  } = props;

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
    }
  }, [id, fetchUser]);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'Done.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/admins');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with update. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  const createUser = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/register`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'Done.',
        placement: 'bottomRight'
      });
      setResult(true);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with creating user. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if ((user?.data && Object.keys(user?.data).length < 1) || !user?.data) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [user]);

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text={isNew ? 'Add new Admin' : 'Edit Admin'} />
        <div className="form-wrapper">
          {id && user.isLoading && (
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          )}
          {id && !user.isLoading && !user.isError && user.data && user.data.email && (
            <AdminForm
              data={user.data}
              updateHandler={updateUser}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
          {id && !user.isLoading && user.isError && (
            <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
          )}
          {!id && user && !user.data && (
            <AdminForm
              data={null}
              createHandler={createUser}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
