import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Button, Input, Table, Tag, Popconfirm, Modal } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import HeaderComponent from '../../components/base/HeaderComponent';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { SERVER_URL } from '../../config';
import { AdminForm } from '../../components/forms';

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [adminForEdit, setAdminForEdit] = useState(null);
  const [newAdmin, setNewAdmin] = useState(null);
  const [result, setResult] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/client-admins`, []);
  }, [fetchUsers]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight'
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${adminForEdit._id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'Done.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/admins');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with update. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  const reload = () => {
    history.push('/');
    history.push('/admins');
  };

  const createUser = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/register`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'Done.',
        placement: 'bottomRight'
      });
      setResult(true);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with creating user. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  const getAdminForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data) setAdminForEdit(response.data);
      else setAdminForEdit(null);
    } catch (error) {
      console.log('Error getting contact for edit:', error?.response?.data?.message);
    }
  };

  let tableData = [];
  if (users.data && users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }
  const title = 'Admins';
  const columns = [
    {
      title: 'Company',
      dataIndex: ['client', 'companyName']
    },
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text, record) => record.role.map((r) => <Tag key={r}>{r}</Tag>)
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 70,
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getAdminForEdit(record._id)}
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: '#90989E' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              disabled={record._id === currentuser.data.id}
              placement="left"
              title={`This will delete ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteUserHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title={`Delete ${title.toLowerCase()}`} style={{ color: '#90989E' }} />
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Admins" />
        <div className="admins-content">
          <div className="admins-container">
            <div className="admins-upper-part">
              <Input
                className="inppy"
                placeholder="Search"
                onChange={(e) =>
                  fetchUsers(`${SERVER_URL}/client-admins?search=${e.target.value}`, [])
                }
                prefix={<Magnifying style={{ marginRight: '5px' }} />}
              />
              <Button onClick={() => setNewAdmin(true)} className="butty" type="primary">
                New admin
              </Button>
            </div>

            <div className="table-manage" style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {users.isLoading && (
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              )}
              {!users.isLoading && users.data.items && users.data.items.length > 0 && (
                <Table
                  bordered
                  dataSource={tableData}
                  columns={columns}
                  rowKey="_id"
                  pagination={{
                    defaultPageSize: 20,
                    position: 'bottom',
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: true
                  }}
                />
              )}
              {!users.isLoading && users.data.items && users.data.items.length === 0 && (
                <h2>NO DATA</h2>
              )}
            </div>
          </div>
        </div>
        {adminForEdit && (
          <div>
            <Modal
              className="update-client-modal"
              width="560px"
              open={adminForEdit !== null}
              onCancel={() => setAdminForEdit(null)}
              footer={null}
              centered
            >
              <div style={{ padding: '10px 36px 40px 36px' }}>
                <div>
                  <AdminForm
                    data={adminForEdit}
                    updateHandler={updateUser}
                    SERVER_URL={SERVER_URL}
                    token={currentuser.data.token}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newAdmin && (
          <div>
            <Modal
              className="update-client-modal"
              width="560px"
              open={newAdmin !== null}
              onCancel={() => setNewAdmin(null)}
              footer={null}
              centered
            >
              <div style={{ padding: '10px 36px 40px 36px' }}>
                <div>
                  <AdminForm
                    data={null}
                    setNewAdmin={setNewAdmin}
                    createHandler={createUser}
                    result={result}
                    reload={reload}
                    setResult={setResult}
                    SERVER_URL={SERVER_URL}
                    token={currentuser.data.token}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Users;
