import React from 'react';
import { ReactComponent as NoIcon } from '../icons/homebase-icons/no-icon.svg';
import { ReactComponent as TargetPatientsIcon } from '../icons/homebase-icons/target-participants.svg';
import { ReactComponent as PlannedStudiesIcon } from '../icons/homebase-icons/planned-studies.svg';
import { ReactComponent as TotalSitesIcon } from '../icons/homebase-icons/total-sites.svg';
import { ReactComponent as TotalUsersIcon } from '../icons/homebase-icons/total-users.svg';
import { ReactComponent as ActivePatientsIcon } from '../icons/homebase-icons/in-study-participants.svg';
import { ReactComponent as ActiveStudiesIcon } from '../icons/homebase-icons/active-studies.svg';
import { ReactComponent as ActiveSitesIcon } from '../icons/homebase-icons/active-sites.svg';
import { ReactComponent as ActiveUsersIcon } from '../icons/homebase-icons/active-users.svg';
import { ReactComponent as TotalQoosIcon } from '../icons/homebase-icons/total-qoos.svg';
import { ReactComponent as TotalQoolectionsIcon } from '../icons/homebase-icons/total-qoolections.svg';
import { ReactComponent as TotalTimeIcon } from '../icons/homebase-icons/total-time.svg';
import { ReactComponent as PublisherLicensesIcon } from '../icons/homebase-icons/monitor-licenses.svg';
import { ReactComponent as CreatorLicensesIcon } from '../icons/homebase-icons/creator-licenses.svg';

const homebaseStats = {
  noicon: {
    icon: <NoIcon />
  },
  targetPatients: {
    icon: <TargetPatientsIcon />,
    label: 'Target Patients',
    type: 'number',
    group: 'overview',
    tooltip: 'The # of Patients intended for this Clinical Trial.'
  },
  plannedStudies: {
    icon: <PlannedStudiesIcon />,
    label: 'Planned Studies',
    type: 'number',
    group: 'overview',
    tooltip: 'The # of planned Studies for this Clinical Trial.'
  },
  totalSites: {
    icon: <TotalSitesIcon />,
    label: 'Total Sites',
    type: 'number',
    group: 'overview',
    tooltip: 'The total # of Sites for this Clinical Trial.'
  },
  totalUsers: {
    icon: <TotalUsersIcon />,
    label: 'Total Users',
    type: 'number',
    group: 'overview',
    tooltip: 'The total # of Users for this Clinical Trial.'
  },
  activePatients: {
    icon: <ActivePatientsIcon />,
    label: 'Active Patients',
    type: 'number',
    group: 'overview',
    tooltip: 'The # of active Patients in this Clinical Trial.'
  },
  activeStudies: {
    icon: <ActiveStudiesIcon />,
    label: 'Active Studies',
    type: 'number',
    group: 'overview',
    tooltip: 'The # of active Studies in this Clinical Trial.'
  },
  activeSites: {
    icon: <ActiveSitesIcon />,
    label: 'Active Sites',
    type: 'number',
    group: 'overview',
    tooltip: 'The # of active Sites in this Clinical Trial.'
  },
  activeUsers: {
    icon: <ActiveUsersIcon />,
    label: 'Active Users',
    type: 'number',
    group: 'overview',
    tooltip: 'The # of active Users in this Clinical Trial.'
  },
  totalQoos: {
    icon: <TotalQoosIcon />,
    label: 'Total Qoos',
    type: 'number',
    group: 'qStats',
    tooltip: 'The total # of Qoos in this Clinical Trial.'
  },
  totalQoolections: {
    icon: <TotalQoolectionsIcon />,
    label: 'Total Qoolections',
    type: 'number',
    group: 'qStats',
    tooltip: 'The total # of Qoolections in this Clinical Trial.'
  },
  totalTime: {
    icon: <TotalTimeIcon />,
    label: 'Total Time',
    type: 'minutes',
    group: 'userStats',
    tooltip: 'The total # of minutes Users spent in this Clinical Trial.'
  },
  averageTime: {
    icon: <TotalTimeIcon />,
    label: 'Average Time',
    type: 'minutes',
    group: 'userStats',
    tooltip: 'The average # of minutes a User spends in this Clinical Trial.'
  },
  patientLicenses: {
    icon: <TotalUsersIcon />,
    label: 'Patient',
    type: 'countOf',
    group: 'licenses',
    tooltip: 'The # of Patient Licenses'
  },
  publisherLicenses: {
    icon: <PublisherLicensesIcon />,
    label: 'Publisher',
    type: 'countOf',
    group: 'licenses',
    tooltip: 'The # of Publisher Licenses'
  },
  creatorLicenses: {
    icon: <CreatorLicensesIcon />,
    label: 'Creator',
    type: 'countOf',
    group: 'licenses',
    tooltip: 'The # of Creator Licenses'
  }
};

export default homebaseStats;
