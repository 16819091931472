import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, notification } from 'antd';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import ClientForm from '../../components/forms/ClientForm';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditClient = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const [clients, fetchClients] = useAxios('', {}, currentuser.data.token, 'get');
  const {
    match: {
      params: { id }
    }
  } = props;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/clients/${id}`, {});
    fetchClients(`${SERVER_URL}/clients`, {});
  }, [fetchData, fetchClients, id]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/clients` : `${SERVER_URL}/clients/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      notification.success({
        message: `Client ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/clients');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
        placement: 'bottomRight'
      });
    }
  };

  const isDataFetched = !data.isLoading && !clients.isLoading && data.data && currentuser.language;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if ((data?.data && Object.keys(data?.data).length < 1) || !data?.data) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [data]);

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text={isNew ? 'Add new Client' : 'Edit Client'} />
        <div>
          {!id && isDataFetched && (
            <ClientForm
              isNew
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              clients={clients}
            />
          )}
          {id && !data.isError && data.data && isDataFetched && (
            <ClientForm
              isNew={false}
              data={data.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              clients={clients}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditClient;
