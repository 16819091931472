import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import './App.scss';

export const UserContext = createContext(null);

// eslint-disable-next-line consistent-return
const handleLogin = async (user) => {
  const allowedRoles = ['superadmin', 'admin'];
  try {
    const userResponse = await Axios.post(
      `${SERVER_URL}/login`,
      { email: user.email, password: user.password },
      { withCredentials: false }
    );
    if (
      userResponse.data.message === 'Auth successful' &&
      allowedRoles.some((role) => userResponse?.data?.role.includes(role))
    ) {
      sessionStorage.setItem('user', JSON.stringify(userResponse.data));
      window.location.reload();
    } else if (
      userResponse.data.message === 'Auth successful' &&
      !allowedRoles.some((role) => userResponse?.data?.role.includes(role))
    )
      return 'You don"t have access to dashboard';
  } catch (err) {
    console.log('Login error = ', err?.response?.data?.message);
    return err?.response?.data?.message;
  }
};

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [language, setLanguage] = useState();
  const [isNavMenuCollapsed, setIsNavMenuCollapsed] = useState(false);

  let user = sessionStorage.getItem('user');
  if (user) user = JSON.parse(user);

  const isForgot = window.location.pathname === '/admin/forgot-password';
  const isReset =
    window.location.pathname.includes('/admin/reset-password') ||
    window.location.pathname.includes('/email-verified');

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        setLanguage({ selected: defaultLanguage, list: resp.data, default: defaultLanguage });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLoggedIn,
        handleLogin,
        data: user,
        language,
        setLanguage,
        isNavMenuCollapsed,
        setIsNavMenuCollapsed
      }}
    >
      <Router>
        {isLoggedIn && <Layout />}
        {!isLoggedIn && !isForgot && !isReset && <Login />}
        {!isLoggedIn && isForgot && !isReset && <ForgotPassword />}
        {!isLoggedIn && !isForgot && isReset && <ResetPassword />}
      </Router>
    </UserContext.Provider>
  );
};

export default App;
