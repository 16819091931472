import React, { useEffect } from 'react';
import { Input, Button, Form, Row, Col, InputNumber } from 'antd';

const { TextArea } = Input;

const formInit = {
  _id: null,
  name: '',
  description: '',
  editing: undefined,
  uploadToVeeva: undefined
};

const StatusForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  const onFinish = async (values, isNew2) => onSubmit(values, isNew2);

  return (
    <div>
      <Form
        className="form-horizontal"
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
        layout="vertical"
        form={form}
      >
        <Row type="flex" gutter={16}>
          <Col>
            <Form.Item rules={[{ required: true, message: 'Please enter name!' }]} name="name">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="description">
              <TextArea rows={6} placeholder="Description" />
            </Form.Item>
            <Form.Item
              label="Editing (1-enabled, 0-disabled)"
              rules={[
                { required: true, message: 'Please enter 1 for enabled 0 for disabled!' },
                // Value must be 1 or 0
                {
                  validator: (_, value) => {
                    if (value === 1 || value === 0) return Promise.resolve();
                    return Promise.reject(new Error('Value must be 1 or 0'));
                  }
                }
              ]}
              name="editing"
            >
              <InputNumber min={0} max={1} />
            </Form.Item>
            <Form.Item
              label="Veeva upload (1-enabled, 0-disabled)"
              rules={[
                { required: true, message: 'Please enter 1 for enabled 0 for disabled!' },
                // Value must be 1 or 0
                {
                  validator: (_, value) => {
                    if (value === 1 || value === 0) return Promise.resolve();
                    return Promise.reject(new Error('Value must be 1 or 0'));
                  }
                }
              ]}
              name="uploadToVeeva"
            >
              <InputNumber min={0} max={1} />
            </Form.Item>
          </Col>
        </Row>

        <div className="text-right">
          <Button type="primary" htmlType="submit">
            {isNew ? 'Add' : 'Edit'} Status
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default StatusForm;
