import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Fuse from 'fuse.js';
import Axios from 'axios';
import { Button, Popconfirm, Table, Modal, Tag, Divider, Empty, Tabs, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import StepNavigation from './StepNavigation';
import BrandForm from '../../components/forms/BrandForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const SettingsStepBrands = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  SERVER_URL,
  addAndEditBrandHandler,
  getBrandForEdit,
  brandForEdit,
  setBrandForEdit,
  deleteBrandHandler,
  brands,
  bulkAddBrandsHandler,
  downloadProductTemplateHandler
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [therapeuticArea, setTherapeuticArea] = useState([]);
  const [therapeuticAreaCondition, setTherapeuticAreaCondition] = useState([]);

  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTherapeuticsAreaConditions = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-condition-for-brands`);
      setTherapeuticAreaCondition(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTherapeuticsArea();
    getTherapeuticsAreaConditions();
  }, [setBulkUploadData]);

  const searchTA = (term) => {
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      threshold: 0.5,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ['name']
    };
    const fuse = new Fuse(therapeuticArea, options);
    const found = fuse.search(term);
    return found[0]?.item._id;
  };

  const searchDS = (term) => {
    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['name']
    };
    const fuse = new Fuse(therapeuticAreaCondition, options);
    const found = fuse.search(term);
    return found[0]?.item._id;
  };

  const uploadData = (file) => {
    const newBulkUploadData = [];
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line consistent-return
        const promises = rows.slice(1).map((r) => {
          console.log('ROw', r);
          if (r[0] && r[1] && r[2] && r[3] && r[4] && r[5] && r[6]) {
            return newBulkUploadData.push({
              key: r[0],
              name: r[1].trim(),
              genericName: r[2].trim(),
              internalTherapeuticArea: r[3].trim(),
              therapeuticArea: searchTA(r[4].trim()),
              internalTherapeuticAreaCondition: r[5].trim(),
              therapeuticAreaCondition: searchDS(r[6].trim())
            });
          }
        });
        await Promise.all(promises);
      });
      resolve(setBulkUploadData(newBulkUploadData));
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Therapeutic Areas',
      dataIndex: 'therapeuticArea',
      render: (text, record) =>
        record.therapeuticArea.map((ta) => (
          <Tag style={{ backgroundColor: '#E5f9c6' }} key={ta._id + 110}>
            {ta.name}
          </Tag>
        ))
    },
    {
      title: 'Conditions',
      dataIndex: 'therapeuticAreaCondition',
      render: (text, record) =>
        record.therapeuticAreaCondition.map((cond) => (
          <Tag style={{ backgroundColor: '#C6e2de' }} key={cond._id + 120}>
            {cond.name}
          </Tag>
        ))
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement="left"
              title={`This will delete ${record.name}`}
              onConfirm={() => deleteBrandHandler(record._id)}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title={`Delete ${record.name}`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getBrandForEdit(record._id)}
              title={`Edit ${record.name}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="company-settings-builder__step-1">
      <div className="company-settings-builder__left-side">
        <h2 className="company-settings-builder__step-subtitle">
          <span>Step 2: </span>
          <span>Add Brands.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className="company-settings-builder__intro-text">
          Add the brands that you will be making your Qoolections for. The table on the right will
          populate as you keep adding brands.
        </p>
        <div className="company-settings-builder__forms">
          <Tabs defaultActiveKey="1" className="company-settings__tabs">
            <Tabs.TabPane tab="Manual upload" key="1">
              <BrandForm
                isNew
                onSubmit={addAndEditBrandHandler}
                SERVER_URL={SERVER_URL}
                createProcess
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bulk upload" key="2">
              <p className="company-settings-builder__intro-text">
                Please download our preformatted template for uploading your Brand information{' '}
                <span
                  style={{ color: '#4962E2', cursor: 'pointer' }}
                  onClick={downloadProductTemplateHandler}
                >
                  here
                  <DownloadOutlined />
                </span>
                . There are additional guidelines for using the template included on the first tab.
                Once you have filled out the template, please return here to upload the file.
              </p>
              <div className="bulk-upload-wrapper">
                <Upload
                  className="bulk-upload-teams"
                  style={{ margin: 0 }}
                  action={uploadData}
                  accept=".xlsx"
                  name="excelData"
                >
                  <Button style={{ margin: 0 }} type="primary">
                    {bulkUploadData && bulkUploadData.length === 0
                      ? 'Upload your completed template'
                      : 'Re-upload'}
                  </Button>
                </Upload>
              </div>
              {bulkUploadData && bulkUploadData.length > 0 && (
                <Button
                  className="bulk-upload-save-button"
                  onClick={() => bulkAddBrandsHandler(bulkUploadData)}
                >
                  Add/Update Brands
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="company-settings-builder__bottom-row-space-between">
          <Button className="company-settings-builder__step-btn" onClick={() => setStep(1)}>
            Previous step
          </Button>
          <Button
            id="company-settings_next-step"
            className="company-settings-builder__step-btn"
            type="primary"
            disabled={brands.length === 0}
            onClick={() => {
              if (completedStep === 1) setCompletedStep(2);
              setStep(3);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type="vertical" style={{ height: 'auto' }} />

      <div className="table-manage">
        <Table
          size="middle"
          bordered
          dataSource={brands}
          columns={columns}
          locale={{ emptyText: <Empty description="No Brands, please add brand." /> }}
          rowKey="_id"
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false
          }}
        />
      </div>

      {brandForEdit && (
        <div>
          <Modal
            visible={brandForEdit}
            onCancel={() => setBrandForEdit(null)}
            footer={null}
            centered
          >
            <div style={{ padding: '8px' }}>
              <div>
                <BrandForm
                  isNew={false}
                  data={brandForEdit}
                  onSubmit={addAndEditBrandHandler}
                  SERVER_URL={SERVER_URL}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SettingsStepBrands;
