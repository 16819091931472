import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Result, Row, Col } from 'antd';
import useAxios from '../../hooks/useAxios';

const initialValues = {
  email: undefined,
  phone: undefined,
  client: undefined,
  firstName: undefined,
  lastName: undefined,
  role: 'admin'
};

const AdminForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  setNewAdmin,
  reload
}) => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [client, fetchSelectedClient] = useAxios('', {}, token, 'get');
  const [clients, fetchClients] = useAxios('', [], token, 'get');
  const user = data ? { ...data } : initialValues;
  const isNew = !data;

  useEffect(() => {
    form.resetFields();
    if (data && data.client) {
      fetchSelectedClient(`${SERVER_URL}/clients/${data.client}`, {});
    }
    fetchClients(`${SERVER_URL}/clients`, []);
  }, []);

  const onFinish = (values) => {
    if (isNew) createHandler(values);
    else updateHandler(values);
  };

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  return (
    <>
      {/* eslint-disable-next-line no-useless-concat */}
      {!result && (
        <Form
          name="basic"
          initialValues={user}
          onFinish={(values) => onFinish(values, isNew)}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
        >
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <h2 className="header-component__page-heading">
              {isNew ? 'Add New Admin' : 'Edit Admin'}
            </h2>
          </div>

          <Row type="flex" gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter first name!'
                  }
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please enter your Email!' },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
                        if (!reg.test(value))
                          return Promise.reject(new Error('Please enter valid email address!'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="client"
                rules={[
                  {
                    required: true,
                    message: 'Please select block client!'
                  }
                ]}
              >
                <Select
                  placeholder="Client"
                  onChange={(value) => {
                    fetchSelectedClient(`${SERVER_URL}/clients/${value}`, {});
                  }}
                >
                  {clients?.data?.items?.map((c) => (
                    <Select.Option value={c._id} key={c._id}>
                      {c.companyName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                hidden
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'Please select role!'
                  }
                ]}
              >
                <Select mode="multiple" placeholder="Role">
                  <Select.Option value="admin">Admin</Select.Option>
                </Select>
              </Form.Item>

              {!isNew && (
                <Form.Item
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Please select status!'
                    }
                  ]}
                >
                  <Select placeholder="Status">
                    <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                    <Select.Option value="SUSPENDED">SUSPENDED</Select.Option>
                    <Select.Option value="DELETED">DELETED</Select.Option>
                    <Select.Option value="WAITING_FOR_ACTIVATION">
                      WAITING_FOR_ACTIVATION
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter last name!'
                  }
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: 'Please enter phone number!' },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const reg = /^[0-9+]{8,}$/g;
                        if (!reg.test(value))
                          return Promise.reject(new Error('Please enter valid phone number!'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                normalize={(value) => value.trim()}
              >
                <Input placeholder="Phone" />
              </Form.Item>

              <Form.Item
                name="jobFunction"
                rules={[
                  {
                    required: true,
                    message: 'Please select function!'
                  }
                ]}
              >
                <Select placeholder="Department">
                  <Select.Option value="IT / Data / Tools">IT / Data / Tools</Select.Option>
                  <Select.Option value="Executive">Executive</Select.Option>
                  <Select.Option value="Medical Affairs">Medical Affairs</Select.Option>
                  <Select.Option value="Sales / Commercial">Sales / Commercial</Select.Option>
                  <Select.Option value="Market Access">Market Access</Select.Option>
                  <Select.Option value="Communications">Communications</Select.Option>
                  <Select.Option value="Clinical">Clinical</Select.Option>
                  <Select.Option value="Other">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              {isNew ? 'Add' : 'Edit'}
              Admin
            </Button>
          </Form.Item>
        </Form>
      )}
      {result && (
        <Result
          title="User has been created and verification mail has been sent"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setNewAdmin(false);
                setResult(false);
                reload();
              }}
            >
              Ok
            </Button>
          }
        />
      )}
    </>
  );
};

export default AdminForm;
