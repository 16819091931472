import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function HomebaseCard({ textContent, image, buttonText, buttonLink }) {
  return (
    <div className="homebase__card">
      <p>{textContent}</p>
      <div className="homebase__card-image">{image}</div>
      <Button type="primary">
        <Link to={buttonLink}>{buttonText}</Link>
      </Button>
    </div>
  );
}
