import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Button, Input, Empty, Popconfirm, Modal, Table } from 'antd';
import { UserContext } from '../../App';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import useAxios from '../../hooks/useAxios';
import HeaderComponent from '../../components/base/HeaderComponent';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { SERVER_URL } from '../../config';
import { ClientForm } from '../../components/forms';

const Clients = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [allClients, fetchAllClients] = useAxios('', [], currentuser.data.token, 'get');
  const [clientForEdit, setClientForEdit] = useState(null);
  const [newClient, setNewClient] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/clients`, []);
    fetchAllClients(`${SERVER_URL}/clients`, []);
  }, [fetchData, fetchAllClients]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/clients');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  const addAndEditClientHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/clients` : `${SERVER_URL}/clients/${clientForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: `Client ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      setClientForEdit(null);
      history.push('/');
      history.push('/clients');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/update.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const getClientForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data) setClientForEdit(response.data);
      else setClientForEdit(null);
    } catch (error) {
      console.log('Error getting contact for edit:', error?.response?.data?.message);
    }
  };

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  const title = 'Clients';

  const columns = [
    {
      title: 'Name',
      dataIndex: 'companyName'
    },
    {
      title: 'Prefix',
      dataIndex: 'companyPrefix'
    },
    {
      title: 'Address',
      dataIndex: 'address'
    },
    {
      title: 'City',
      dataIndex: 'city'
    },
    {
      title: 'Zip Code',
      dataIndex: 'zip'
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'State',
      dataIndex: 'state'
    },
    {
      title: 'Sector',
      dataIndex: 'sector'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getClientForEdit(record._id)}
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              disabled={record._id === currentuser.data.id}
              placement="left"
              title={`This will delete ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteDataHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title={`Delete ${title.toLowerCase()}`} />
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Clients" />
        <div className="clients-content">
          <div className="clients-container">
            <div className="clients-upper-part">
              <Input
                className="inppy"
                placeholder="Search"
                onChange={(e) => fetchData(`${SERVER_URL}/clients?search=${e.target.value}`)}
                prefix={<Magnifying style={{ marginRight: '5px' }} />}
              />
              <Button onClick={() => setNewClient(true)} className="butty" type="primary">
                Add new client
              </Button>
            </div>

            <div className="table-manage" style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {data.isLoading && (
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              )}
              {!data.isLoading && tableData?.length > 0 && (
                <Table
                  size="middle"
                  editPath="/edit-client/"
                  bordered
                  dataSource={tableData}
                  columns={columns}
                  locale={{ emptyText: <Empty description="No Clients, please add client." /> }}
                  rowKey="_id"
                  pagination={{
                    defaultPageSize: 20,
                    position: 'bottom',
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: false
                  }}
                />
              )}
              {!data.isLoading && tableData.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
        {clientForEdit && (
          <div>
            <Modal
              className="update-client-modal"
              width="560px"
              open={clientForEdit !== null}
              onCancel={() => setClientForEdit(null)}
              footer={null}
              centered
            >
              <div style={{ padding: '10px 36px 40px 36px' }}>
                <div>
                  <ClientForm
                    isNew={false}
                    data={clientForEdit}
                    clients={allClients}
                    onSubmit={addAndEditClientHandler}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newClient && (
          <div>
            <Modal
              className="update-client-modal"
              width="560px"
              open={newClient !== null}
              onCancel={() => setNewClient(null)}
              footer={null}
              centered
            >
              <div style={{ padding: '10px 36px 40px 36px' }}>
                <div>
                  <ClientForm
                    isNew
                    clients={allClients}
                    onSubmit={addAndEditClientHandler}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Clients;
