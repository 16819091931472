import React from 'react';

export default function Footer() {
  const currYear = new Date().getFullYear();

  return (
    <footer className="footer-component">
      <ul className="footer-links">
        <li className="">&copy; {currYear} Qoolo Inc</li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.qoolo.com/terms-of-service">
            Terms of Service
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.qoolo.com/privacy-policy">
            Privacy Policy
          </a>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://www.qoolo.com/cookie-policy">
            Cookie Policy
          </a>
        </li>
      </ul>
    </footer>
  );
}
