import React, { useEffect } from 'react';
import { Button, Form, Row, Col, Select, Tabs } from 'antd';
import useAxios from '../../hooks/useAxios';

const formInit = {
  audiences: {},
  therapeuticAreas: {},
  documentType: '',
  documentSubType: '',
  documentLifeCycle: ''
};

const VeevaMappingForm = ({ mapData, onSubmit, user, SERVER_URL }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...mapData };
  const [documentTypes, fetchDocumentTypes] = useAxios('', [], user.token, 'get');
  const [typeProperties, fetchTypeProperties] = useAxios('', [], user.token, 'get');
  const [audiencePicklist, fetchAudiencePicklist] = useAxios('', [], user.token, 'get');
  const [therapeuticAreaPicklist, fetchTherapeuticAreaPicklist] = useAxios(
    '',
    [],
    user.token,
    'get'
  );
  // const [qooloStatuses, fetchQooloStatuses] = useAxios('', [], user.token, 'get');
  const [qooloAudiences, fetchQooloAudiences] = useAxios('', [], user.token, 'get');
  const [qooloTherapeuticAreas, fetchQooloTherapeuticAreas] = useAxios('', [], user.token, 'get');

  useEffect(() => {
    fetchDocumentTypes(`${SERVER_URL}/veeva-get-document-types/${user.id}`, {});
    fetchAudiencePicklist(`${SERVER_URL}/veeva-get-audience-picklist/${user.id}`, {});
    fetchTherapeuticAreaPicklist(
      `${SERVER_URL}/veeva-get-therapeutic-area-picklist/${user.id}`,
      {}
    );
    // fetchQooloStatuses(`${SERVER_URL}/statuses`, {});
    fetchQooloAudiences(`${SERVER_URL}/audiences`, {});
    fetchQooloTherapeuticAreas(`${SERVER_URL}/therapeutic-area`, {});
    form.resetFields();
  }, [
    fetchDocumentTypes,
    fetchTypeProperties,
    fetchAudiencePicklist,
    fetchQooloAudiences,
    fetchTherapeuticAreaPicklist,
    fetchQooloTherapeuticAreas
  ]);

  const onFinish = async (values) => onSubmit(values);

  return (
    <div className="panel panel-body">
      <div className="panel-body">
        <Form
          className="form-horizontal"
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout="vertical"
          form={form}
        >
          <Row type="flex" gutter={24}>
            <Col xs={12} md={8}>
              <div className="panel-body">
                <Tabs tabPosition="top" defaultActiveKey="Document">
                  <Tabs.TabPane tab="Document" key="Document" forceRender>
                    <Form.Item
                      label="Document type"
                      name="documentType"
                      rules={[
                        {
                          required: true,
                          message: 'Please select document type!'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Document type"
                        onChange={() => {
                          fetchDocumentTypes(
                            `${SERVER_URL}/veeva-get-document-types/${user.id}`,
                            {}
                          );
                        }}
                        onSelect={(value) => {
                          console.log(value);
                          fetchTypeProperties(
                            `${SERVER_URL}/veeva-get-type-properties/${user.id}/${value.split(',')[0]}`,
                            {}
                          );
                        }}
                      >
                        {documentTypes?.data?.types?.map((type) => (
                          <Select.Option
                            value={type.value.split('/').pop() + ',' + type.label}
                            key={type.value}
                          >
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Document subtype"
                      name="documentSubType"
                      rules={[
                        {
                          required: true,
                          message: 'Please select document subtype!'
                        }
                      ]}
                    >
                      <Select placeholder="Document subtype">
                        {typeProperties?.data?.subtypes?.map((subtype) => (
                          <Select.Option value={subtype.label} key={subtype.label}>
                            {subtype.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Document lifecycle"
                      name="documentLifeCycle"
                      rules={[
                        {
                          required: true,
                          message: 'Please select document lifecycle!'
                        }
                      ]}
                    >
                      <Select placeholder="Document lifecycle">
                        {typeProperties?.data?.availableLifecycles &&
                          typeProperties.data.availableLifecycles.map((lifecycle) => (
                            <Select.Option value={lifecycle.label} key={lifecycle.name}>
                              {lifecycle.label}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Audience" key="Audience">
                    {qooloAudiences?.data?.items?.map((a) => (
                      <Form.Item
                        key={a._id}
                        label={a.name}
                        name={['audiences', a._id]}
                        rules={[
                          {
                            required: true,
                            message: 'Please select matching audience'
                          }
                        ]}
                      >
                        <Select placeholder="Match to veeva audience__vs">
                          {audiencePicklist?.data?.picklistValues?.map((audience) => (
                            <Select.Option key={audience.label} value={audience.name}>
                              {audience.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ))}
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Therapeutic area" key="Therapeutic_Area">
                    {qooloTherapeuticAreas?.data?.items?.map((a) => (
                      <Form.Item
                        key={a._id}
                        label={a.name}
                        name={['therapeuticAreas', a._id]}
                        rules={[
                          {
                            required: true,
                            message: 'Please select matching therapeutic area'
                          }
                        ]}
                      >
                        <Select showSearch placeholder="Match to veeva therapeutic_area__vs">
                          {therapeuticAreaPicklist?.data?.picklistValues?.map((area) => (
                            <Select.Option key={area.label} value={area.name}>
                              {area.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ))}
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>

          <div className="text-right">
            <Button type="primary" htmlType="submit">
              Update Veeva mappings
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default VeevaMappingForm;
