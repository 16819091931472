import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { SearchOutlined, ExportOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Layout, Select, Empty, Table, Input, Button, Space } from 'antd';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import HeaderComponent from '../../components/base/HeaderComponent';

const Quiz = () => {
  const currentuser = useContext(UserContext);
  const [quizQoolections, setQuizQoolections] = useState([]);
  const [selectedQuizQoolectionId, setSelectedQuizQoolectionId] = useState(null);
  const [quiz, setQuiz] = useState([]);
  const [setSearchText] = useState('');
  const [setSearchedColumn] = useState('');
  const searchInput = useRef();

  const getAllQuizQoolections = async () => {
    const response = await Axios.get(`${SERVER_URL}/get-all-quiz-qoolections`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    setQuizQoolections(response?.data);
  };

  const getQuizQoolection = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/get-quiz-qoolection-results/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    // Remap quiz data, add correctAnswers and incorrectAnswers numbers properties
    const quizData = await response?.data?.map((q) => {
      const correctAnswers = q.results.filter((r) => r.answerValue === '1');
      const incorrectAnswers = q.results.filter((r) => r.answerValue === '0');
      return {
        ...q,
        correctAnswers: correctAnswers.length,
        incorrectAnswers: incorrectAnswers.length
      };
    });
    setQuiz(quizData);
  };

  useEffect(() => {
    getAllQuizQoolections();
  }, []);

  useEffect(() => {
    if (selectedQuizQoolectionId) {
      getQuizQoolection(selectedQuizQoolectionId);
    }
  }, [selectedQuizQoolectionId]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText('');
  }, []);

  const ExportToExcel = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const tableElt = document.getElementById('quiz-table');
    const workbook = XLSX.utils.table_to_book(tableElt);

    // Identify the default sheet name.
    const sheetName = workbook.SheetNames[0];

    // Get the worksheet.
    const worksheet = workbook.Sheets[sheetName];

    // Extract data from worksheet.
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Remove the first column from every row.
    const newData = jsonData.map((row) => row.slice(1));

    // Generate a new workbook with the modified data.
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, XLSX.utils.aoa_to_sheet(newData), sheetName);

    newWorkbook.Sheets[sheetName]['!cols'] = [
      { wpx: 270 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 180 },
      { wpx: 150 }
    ];

    const excelBuffer = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, 'quiz-report.xlsx');
  };

  const getColumnSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 110, marginRight: 5 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 110 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, fontSize: 20 }} />
      ),
      onFilter: (value, record) => {
        switch (dataIndex) {
          case 'contact':
            return record?.contact?.token
              ? record.contact.token.toString().toLowerCase().includes(value.toLowerCase())
              : '';
          default:
            return record[dataIndex]
              ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
              : '';
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) setTimeout(() => searchInput.current?.select(), 100);
      },
      render: (record) => {
        switch (dataIndex) {
          case 'contact':
            // eslint-disable-next-line react/jsx-one-expression-per-line
            return (
              <p>
                <b style={{ fontSize: 16, color: 'darkblue' }}>
                  {record.contact.token.substring(0, 5)}
                </b>
                {record.contact.token.substring(5)}
              </p>
            );
          default:
            return record[dataIndex];
        }
      }
    };
  };

  const columns = [
    {
      title: 'Contact ID',
      render: (contact) => contact && contact.token,
      ...getColumnSearchProps('contact', 'token')
    },
    {
      title: 'Correct answers',
      render: (record) => record.correctAnswers,
      ...getColumnSearchProps('correctAnswers'),
      sorter: (a, b) => b.correctAnswers - a.correctAnswers,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Incorrect answers',
      render: (record) => record.incorrectAnswers,
      sorter: (a, b) => b.incorrectAnswers - a.incorrectAnswers,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Completition duration (seconds)',
      // Calculate and render completition duration by summing all durations in results array  -> use example data
      render: (record) => {
        const duration = record.results.reduce((acc, curr) => acc + curr.duration, 0);
        return duration / 1000;
      },
      sorter: (a, b) => {
        const aDuration = a.results.reduce((acc, curr) => acc + curr.duration, 0);
        const bDuration = b.results.reduce((acc, curr) => acc + curr.duration, 0);
        return bDuration - aDuration;
      },
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Time of completition',
      // Show time of completition based on last answer
      render: (record) => {
        // First sort answers by eventTime
        record.results.sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));
        const lastAnswer = record.results[record.results.length - 1];
        return moment(lastAnswer.eventTime).format('h:mm:ss:SSS A');
      }
    }
  ];

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Manage Builders" />
        <div className="teams-content">
          <div className="teams-container">
            <div className="teams-upper-part">
              <Select
                placeholder="Select quiz...."
                onChange={async (value) => await setSelectedQuizQoolectionId(value)}
                style={{ width: '30%' }}
                showArrow
              >
                {quizQoolections &&
                  quizQoolections.map((q, index) => (
                    <Select.Option key={index} value={q._id}>
                      {q.title}
                    </Select.Option>
                  ))}
              </Select>
              <Button
                type="primary"
                size="small"
                style={{ marginLeft: '15px' }}
                title="Export to Excel"
                onClick={() => ExportToExcel()}
              >
                <ExportOutlined />
                Export to excel
              </Button>
            </div>

            <div>
              <Table
                id="quiz-table"
                size="large"
                style={{ border: '2px solid #e8e8e8' }}
                bordered
                dataSource={quiz}
                columns={columns}
                locale={{ emptyText: <Empty description="No quiz, please select quiz." /> }}
                rowKey="_id"
                expandable={{
                  expandedRowRender: (record) =>
                    record.results.map((r, index) => (
                      <div key={index} style={{ border: '2px solid #e8e8e8', padding: '10px' }}>
                        <p style={{ margin: 0 }}>
                          Question: <b>{r.question}</b>
                        </p>
                        <p style={{ margin: 0 }}>
                          Answer: <b>{r.answer}</b>
                        </p>
                        <p style={{ margin: 0 }}>
                          Correct: <b>{r.answerValue === '1' ? 'YES' : 'No'}</b>
                        </p>
                        <p style={{ margin: 0 }}>
                          Answer time: <b>{moment(r.eventTime).format('h:mm:ss:SSS A')}</b>
                        </p>
                        <p style={{ margin: 0 }}>
                          Answer duration: <b>{r.duration / 1000} seconds</b>
                        </p>
                      </div>
                    )),
                  rowExpandable: (record) => record.name !== 'Not Expandable'
                }}
                pagination={{
                  defaultPageSize: 500,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                  hideOnSinglePage: true
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Quiz;
