import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Button } from 'antd';
import { UserContext } from '../../App';
import HeaderComponent from '../../components/base/HeaderComponent';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/Table';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt'];

const Audiences = () => {
  const currentuser = useContext(UserContext);
  const [audiences, fetchAudiences] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchAudiences(`${SERVER_URL}/audiences`, []);
  }, [fetchAudiences]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/audiences/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/audiences');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  let columnKeys;
  if (audiences.data && audiences.data.items && audiences.data.items.length > 0) {
    const keys = Object.keys(audiences.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (audiences.data && audiences.data.items && audiences.data.items.length > 0) {
    tableData = audiences.data.items.map((item) => {
      return item;
    });
  }

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Audiences" />
        <div className="audiences-content">
          <div className="audiences-container">
            <div className="audiences-upper-part">
              <Link to="/new-audience">
                <Button className="butty" type="primary">
                  Add new audience
                </Button>
              </Link>
            </div>

            <div
              className="audiences-table"
              style={{ textAlign: 'center', backgroundColor: 'white' }}
            >
              {audiences.isLoading && (
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              )}
              {!audiences.isLoading &&
                audiences.data &&
                audiences.data.items &&
                audiences.data.items.length > 0 && (
                  <Table
                    data={tableData}
                    deleteHandler={deleteDataHandler}
                    columnKeys={columnKeys}
                    title="Audiences"
                    editPath="/edit-audience/"
                  />
                )}
              {!audiences.isLoading &&
                audiences.data &&
                audiences.data.items &&
                audiences.data.items.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Audiences;
