import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Button, Input, Modal, Form } from 'antd';
import { UserContext } from '../../App';
import HeaderComponent from '../../components/base/HeaderComponent';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import VeevaMappingForm from '../../components/forms/VeevaMappingForm';

const VeevaMapping = () => {
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios('', {}, currentuser.data.token, 'get');
  const [audiences, fetchAudiences] = useAxios('', [], currentuser.data.token, 'get');
  const clientId = currentuser?.data?.client?._id;
  const history = useHistory();
  const [connectionModalVisible, setConnectionModalVisible] = useState(false);
  const [veevaConnected, setVeevaConnected] = useState(false);
  const [vaultDNS, setVaultDNS] = useState('');
  const [vaultUsername, setVaultUsername] = useState('');

  const checkVeevaConnectionStatus = async () => {
    console.log('Checking Veeva connection status');
    try {
      const response = await Axios.get(
        `${SERVER_URL}/veeva-check-connection-status/${currentuser.data.id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      console.log(response?.data);
      if (response.data.status === 'SUCCESS') {
        // Get Veeva settings for client
        setVeevaConnected(true);
      } else {
        setVaultDNS(response.data.vaultDNS || '');
        setVaultUsername(response.data.username || '');
        setVeevaConnected(false);
      }
    } catch (e) {
      console.log(e);
      setVeevaConnected(false);
    }
  };

  useEffect(() => {
    fetchClient(`${SERVER_URL}/clients/${clientId}`, []);
    fetchAudiences(`${SERVER_URL}/audiences`, []);
    checkVeevaConnectionStatus();
  }, [veevaConnected, fetchClient, clientId, fetchAudiences]);

  const handlSubmitForVeevaLogin = async (values) => {
    if (!currentuser.data.id) {
      notification.error({
        message: 'No logged in user info.',
        placement: 'bottomRight'
      });
    }
    try {
      const response = await Axios.post(
        `${SERVER_URL}/veeva/${currentuser.data.id}/connect`,
        values,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      console.log(response);
      if (response.data.status === 'SUCCESS') {
        notification.success({
          message: 'Connected to veeva vault.',
          placement: 'bottomRight'
        });
        setVeevaConnected(true);
        setConnectionModalVisible(false);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Problem with log in to veeva vault.',
        placement: 'bottomRight',
        duration: 3
      });
    }
  };

  const handleCancelConnectionModal = () => {
    setConnectionModalVisible(false);
  };

  const onSubmit = async (formData) => {
    console.log(formData);
    try {
      await Axios.put(`${SERVER_URL}/clients-update-veeva-mapping/${clientId}`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      notification.success({
        message: 'Mapping updated.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/veeva-mapping');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
        placement: 'bottomRight'
      });
    }
  };

  return (
    <div>
      <HeaderComponent text="Veeva mapping" />
      <div style={{ textAlign: 'center', padding: '8px' }}>
        <Button
          type="primary"
          onClick={() => setConnectionModalVisible(true)}
          disabled={veevaConnected}
        >
          {veevaConnected ? 'CONNECTED TO VAULT' : 'Veeva connect'}
        </Button>

        {veevaConnected && (
          <VeevaMappingForm
            mapData={client?.data?.veevaMappings || {}}
            qooloAudiences={audiences.data.items}
            SERVER_URL={SERVER_URL}
            user={currentuser.data}
            onSubmit={onSubmit}
          />
        )}
      </div>

      {/* Connection modal */}
      <Modal
        title="Veeva connection options"
        open={connectionModalVisible}
        footer={null}
        onCancel={handleCancelConnectionModal}
      >
        <Form
          layout="vertical"
          className="pre-create-block__form"
          initialValues={{ username: vaultUsername, vaultDNS }}
          onFinish={(values) => handlSubmitForVeevaLogin(values)}
        >
          <Form.Item
            label="Vault"
            rules={[{ required: true, message: 'Please enter vault!' }]}
            name="vaultDNS"
            labelAlign="left"
          >
            <Input placeholder="Vault" className="input-medium" />
          </Form.Item>
          <Form.Item
            label="User name"
            rules={[{ required: true, message: 'Please enter username!' }]}
            name="username"
            labelAlign="left"
          >
            <Input placeholder="username" className="input-medium" />
          </Form.Item>
          <Form.Item
            label="Password"
            rules={[{ required: true, message: 'Please enter password!' }]}
            name="password"
            labelAlign="left"
          >
            <Input placeholder="Password" className="input-medium" />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            Continue
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default VeevaMapping;
