import React, { useContext, useEffect } from 'react';
import { Layout, Skeleton } from 'antd';
import useAxios from '../hooks/useAxios';
import { SERVER_URL } from '../config';
import { UserContext } from '../App';
import HeaderComponent from '../components/base/HeaderComponent';
import HomebaseWidget from '../components/homebase/HomebaseWidget';
import HomebaseStatsList from '../components/homebase/HomebaseStatsList';
import HomebaseCard from '../components/homebase/HomebaseCard';
import HomebaseLicensesList from '../components/homebase/HomebaseLicensesList';
import { ReactComponent as ManagePatientsImage } from '../icons/homebase-icons/manage-patients.svg';
import { ReactComponent as ManageStudiesImage } from '../icons/homebase-icons/manage-studies.svg';
import { ReactComponent as ManageUsersImage } from '../icons/homebase-icons/manage-users.svg';

const { Content } = Layout;

const Home = () => {
  const user = useContext(UserContext);
  const isAdmin = user?.data?.role.some((r) => r === 'admin');
  const [statistics, fetchStatistics] = useAxios('', null, user.data.token, 'get');

  useEffect(() => {
    fetchStatistics(
      `${SERVER_URL}/clients-admin-homebase-statistics`,
      null,
      user.data.token,
      'get'
    );
  }, [fetchStatistics, user.data.token]);

  const overviewStatsOne = {
    targetPatients: statistics?.data?.totalPatientsCreated,
    plannedStudies: statistics?.data?.totalStudies,
    totalSites: statistics?.data?.totalSites,
    totalUsers: statistics?.data?.totalUsers
  };
  const overviewStatsTwo = {
    activePatients: statistics?.data?.activePatients,
    activeStudies: statistics?.data?.activeStudies,
    activeSites: statistics?.data?.activeSites,
    activeUsers: statistics?.data?.activeUsers
  };
  const qStats = {
    totalQoos: statistics?.data?.totalQoos,
    totalQoolections: statistics?.data?.totalQoolections
  };
  const userStats = {
    totalTime: statistics?.data?.totalTime,
    averageTime: statistics?.data?.averageTime
  };
  const licensesStats = {
    publisherLicenses: `${statistics?.data?.totalPublisherLicencesUsed} / ${statistics?.data?.totalPublisherLicences}`,
    creatorLicenses: `${statistics?.data?.totalCreatorLicencesUsed} / ${statistics?.data?.totalCreatorLicences}`
  };

  return (
    <Layout className="homebase__layout">
      <HeaderComponent text={`Welcome to Qoolo, ${user.data.firstName} ${user.data.lastName}`} />
      <Content className="homebase__wrapper">
        {isAdmin && (
          <div className="homebase__content">
            <HomebaseWidget maxWidth="630px" title="What do you want to do today?">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="flex-row" style={{ marginBottom: '30px' }}>
                  <HomebaseCard
                    textContent="Add a new contact or manage existing ones with ease.
                    Access the Manage Contact section now to get started."
                    image={<ManagePatientsImage />}
                    buttonText="Manage Contacts"
                    buttonLink="/contacts"
                  />
                  <HomebaseCard
                    textContent="Easily add a new brand or manage an existing one in just a few clicks. Head to the Manage Brands section to take action now."
                    image={<ManageStudiesImage />}
                    buttonText="Manage Brands"
                    buttonLink="/brands"
                  />
                </div>
                <div className="flex-row">
                  <HomebaseCard
                    textContent="Add new users or manage existing ones in one place. Visit the Manage Users section to get started today."
                    image={<ManageUsersImage />}
                    buttonText="Manage Users"
                    buttonLink="/client-users"
                  />
                </div>
              </div>
            </HomebaseWidget>
            <section className="homebase__column">
              <HomebaseWidget title="Overview">
                <div className="homebase__list-group">
                  {statistics?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <HomebaseStatsList
                        group="overview"
                        data={overviewStatsOne}
                        loading={false}
                        setLoading={() => {}}
                      />
                      <HomebaseStatsList
                        group="overview"
                        data={overviewStatsTwo}
                        loading={false}
                        setLoading={() => {}}
                      />
                    </>
                  )}
                </div>
              </HomebaseWidget>
              <div className="flex-row">
                <HomebaseWidget
                  title="Q-Stats"
                  tooltip={
                    <div className="homebase__tooltip">
                      <p>
                        <strong>Participants</strong>
                        shows the # of clinical trial participants that have enrolled in the site
                        and passed Demo Mode for Qoolo.
                      </p>
                    </div>
                  }
                >
                  {statistics?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <HomebaseStatsList
                      group="qStats"
                      data={qStats}
                      loading={false}
                      setLoading={() => {}}
                      single
                    />
                  )}
                </HomebaseWidget>
                <HomebaseWidget
                  title="User Stats"
                  tooltip={
                    <div className="homebase__tooltip">
                      <p>
                        <strong>Participants</strong>
                        shows the # of clinical trial participants that have enrolled in the site
                        and passed Demo Mode for Qoolo.
                      </p>
                    </div>
                  }
                >
                  {statistics?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <HomebaseStatsList
                      group="userStats"
                      data={userStats}
                      loading={false}
                      setLoading={() => {}}
                      single
                    />
                  )}
                </HomebaseWidget>
              </div>
            </section>
            <section className="homebase__column">
              <HomebaseWidget
                title="Licenses"
                tooltip={
                  <div className="homebase__tooltip">
                    <p>
                      <strong>Participants</strong>
                      shows the # of clinical trial participants that have enrolled in the site and
                      passed Demo Mode for Qoolo.
                    </p>
                  </div>
                }
              >
                {statistics?.isLoading ? (
                  <Skeleton active />
                ) : (
                  <HomebaseLicensesList
                    group="licenses"
                    data={licensesStats}
                    loading={false}
                    setLoading={() => {}}
                  />
                )}
              </HomebaseWidget>
            </section>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Home;
