import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Card } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { UserContext } from "../App";
import AuthMessage from "../components/elements/AuthMessage";
import Footer from "../components/base/Footer";
import Logo from "../components/base/Logo";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Login = () => {
  const user = useContext(UserContext);
  // const [verifyMessage, setVerifyMessage] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");

  useEffect(() => {
    const checkForVerification = window.location.href.split("/");
    if (
      checkForVerification &&
      checkForVerification[checkForVerification.length - 1].includes(
        "email-verified",
      )
    ) {
      // setVerifyMessage('Email verified, please log in.');
      setLoginMessage({
        message: "Email verified, please log in.",
        type: "success",
      });
      console.log(checkForVerification);
    }
  }, []);

  const onFinish = async (values) => {
    setLoginMessage("");
    user.handleLogin(values);
    const result = await user.handleLogin(values);
    setLoginMessage({ message: result, type: "error" });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Error:", errorInfo);
  };

  return (
    <div className="log">
      <div className="card-wrapper">
        <Logo width={240} style={{ marginBottom: "40px" }} />
        {/* <h1>Qoolo</h1> */}

        <Card title="Log In" className="login-header" bordered={false}>
          {loginMessage && <AuthMessage content={loginMessage} />}
          <Form
            {...layout}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email"
              style={{ marginBottom: "12px" }}
              rules={[
                { required: true, message: "Please enter your Email!" },
                {
                  validator: (_, value) => {
                    if (value) {
                      const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
                      if (!reg.test(value))
                        return Promise.reject(
                          new Error("Please enter valid email address!"),
                        );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className="login-button" type="primary" htmlType="submit">
                LOG IN
              </Button>
            </Form.Item>
          </Form>
          <div className="forgot-pass">
            <a href="/admin/forgot-password">Forgot password?</a>
          </div>
        </Card>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
