import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { Button, notification } from 'antd';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import BlockTypeForm from '../../components/forms/BlockTypeForm';

const EditBlockType = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [blockType, fetchBlockType] = useAxios('', {}, currentuser.data.token, 'get');
  const {
    match: {
      params: { blockTypeId }
    }
  } = props;

  useEffect(() => {
    if (blockTypeId) fetchBlockType(`${SERVER_URL}/block-types/${blockTypeId}`, {});
  }, [fetchBlockType, blockTypeId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/block-types` : `${SERVER_URL}/block-types/${blockTypeId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      notification.success({
        message: `Block Type ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight'
      });
      history.push('/block-types');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
        placement: 'bottomRight'
      });
    }
  };

  const isDataFetched = !blockType.isLoading && blockType.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div className="actions-block">
        <Link to="/block-types">
          <Button type="primary">All Block Types</Button>
        </Link>
      </div>
      <div>
        {!blockTypeId && isDataFetched && (
          <BlockTypeForm
            isNew
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {blockTypeId && !blockType.isError && blockType.data && isDataFetched && (
          <BlockTypeForm
            isNew={false}
            data={blockType.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditBlockType;
