import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form, Divider, Row, Col } from 'antd';
import countries from '../../lib/countries';

const formInit = {
  _id: null,
  therapeuticArea: null
};

const typeOfHCP = [
  'Medical Doctor',
  'Nurse Practitioner ',
  'Nutritionist',
  'Nursing Assistant',
  'Nurse Midwife',
  'Occupational Therapist',
  'Pharmacy Technician',
  'Physician Assistant ',
  'Physical Therapist',
  'Registered Nurse',
  'Speech Pathologist'
];

const ContactForm = ({ isNew, data, onSubmit, SERVER_URL, createProcess = false }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...data };
  const [therapeuticArea, setTherapeuticArea] = useState([]);

  const onFinish = async (values, isNew2) => onSubmit(values, isNew2);

  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTherapeuticsArea();
  }, []);

  return (
    <div className="panel">
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout="vertical"
          form={form}
        >
          {!createProcess && (
            <div className="modal-team__header">
              <h2>{isNew ? 'Add new' : 'Update'} contact</h2>
            </div>
          )}
          <div className="modal-team__input">
            <Divider orientation="left" plain>
              Personal Information
            </Divider>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  rules={[{ required: true, message: 'Please enter brand name!' }]}
                  name="firstName"
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName">
                  <Input />
                </Form.Item>
                <Form.Item label="Employer" name="employer">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Middle Name" name="middleName">
                  <Input />
                </Form.Item>
                <Form.Item label={<span />} name="middleName">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item label="Employer Country" name="employerCountry">
                  <Select placeholder="Select an option" allowClear showSearch>
                    {countries &&
                      countries.map((c) => (
                        <Select.Option key={c.code} value={c.name}>
                          {c.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" plain>
              Professional Information
            </Divider>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Type of HCP"
                  name="typeHCP"
                  rules={[{ required: true, message: 'Please select HCP type' }]}
                >
                  <Select placeholder="Select an option" allowClear showSearch>
                    {typeOfHCP &&
                      typeOfHCP.map((t) => (
                        <Select.Option key={t} value={t}>
                          {t}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Secondary Speciality" name="secondarySpeciality">
                  <Select placeholder="Select an option" allowClear showSearch>
                    {therapeuticArea &&
                      therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Primary Speciality" name="primarySpeciality">
                  <Select placeholder="Select an option" allowClear showSearch>
                    {therapeuticArea &&
                      therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" plain>
              Contact Information
            </Divider>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Country Dial Code" name="countryCode">
                  <Select placeholder="Select an option" allowClear showSearch>
                    {countries &&
                      countries.map((c) => (
                        <Select.Option key={c.code} value={c.dial_code}>
                          {c.dial_code}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    { required: false, message: 'Please enter phone number!' },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const reg = /^[0-9+]{8,}$/g;
                          if (!reg.test(value))
                            return Promise.reject(new Error('Please enter valid phone number!'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="company-settings-builder__save-levels-button">
            <Button
              id="add-contact-button"
              type="primary"
              htmlType="submit"
              className="margin-top-20"
            >
              {isNew ? 'Add' : 'Update'} contact
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
