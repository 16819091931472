/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Input, Button, Form, Row, Col } from 'antd';

const { TextArea } = Input;

const formInit = {
  _id: null,
  name: '',
  description: ''
};

const CategoryForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  const onFinish = async (values, isNew2) => onSubmit(values, isNew2);

  return (
    <div>
      <Form
        className="form-horizontal"
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
        layout="vertical"
        form={form}
      >
        <Row type="flex" gutter={16}>
          <Col>
            <Form.Item rules={[{ required: true, message: 'Please enter name!' }]} name="name">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder="Description" rows={6} />
            </Form.Item>
          </Col>
        </Row>

        <div className="text-right">
          <Button type="primary" htmlType="submit">
            {isNew ? 'Add' : 'Edit'} Category
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CategoryForm;
