import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Checkbox, Tag } from 'antd';
import pluralize from 'pluralize';
import { EyeOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const DefaultTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  actions = 'edit delete'
}) => {
  const user = useContext(UserContext);

  const columns = columnKeys.map((item) => {
    if (item === 'editing') {
      return {
        key: item,
        title: item,
        dataIndex: item,
        render: (text, record) => <Checkbox style={{ margin: 'auto' }} checked={record.editing} />
      };
    }
    if (item === 'uploadToVeeva') {
      return {
        key: item,
        title: item,
        dataIndex: item,
        render: (text, record) => <Checkbox checked={record.uploadToVeeva} />
      };
    }
    if (item === 'conditions') {
      return {
        key: item,
        title: item,
        dataIndex: item,
        render: (text, record) => (
          <div>
            {record.conditions.map((c) => (
              <Tag key={c._id} color="geekblue">
                {c.name}
              </Tag>
            ))}
          </div>
        )
      };
    }
    return {
      key: item,
      title: item,
      dataIndex: item
    };
  });

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px'
        }}
      >
        {viewPath && (
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                title={`View ${pluralize.singular(title.toLowerCase())}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        {actions.includes('edit') && (
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Link to={editPath + record._id}>
              <Edit
                title={`Edit ${pluralize.singular(title.toLowerCase())}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        {actions.includes('delete') && (
          <div style={{ margin: '2px', padding: '2px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement="left"
              title={`This will delete ${pluralize.singular(title.toLowerCase())}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title={`Delete ${pluralize.singular(title.toLowerCase())}`} />
            </Popconfirm>
          </div>
        )}
      </div>
    )
  });

  return (
    <div className="table-manage">
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 10,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true
        }}
      />
    </div>
  );
};

export default DefaultTable;
