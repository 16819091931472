import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, notification } from 'antd';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import CategoryForm from '../../components/forms/CategoryForm';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditCategory = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [category, fetchCategory] = useAxios('', {}, currentuser.data.token, 'get');
  const {
    match: {
      params: { categoryId }
    }
  } = props;

  useEffect(() => {
    if (categoryId) fetchCategory(`${SERVER_URL}/categories/${categoryId}`, {});
  }, [fetchCategory, categoryId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/categories` : `${SERVER_URL}/categories/${categoryId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      notification.success({
        message: `Category ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight'
      });
      history.push('/categories');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
        placement: 'bottomRight'
      });
    }
  };

  const isDataFetched = !category.isLoading && category.data && currentuser.language;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if ((category?.data && Object.keys(category?.data).length < 1) || !category?.data) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [category]);

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text={isNew ? 'Add new Category' : 'Edit Category'} />
        <div className="form-wrapper">
          {!categoryId && isDataFetched && (
            <CategoryForm
              isNew
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
          {categoryId && !category.isError && category.data && isDataFetched && (
            <CategoryForm
              isNew={false}
              data={category.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditCategory;
