import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Axios from "axios";
import { SERVER_URL } from "../config";
import AuthMessage from "../components/elements/AuthMessage";
import Footer from "../components/base/Footer";
import Logo from "../components/base/Logo";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};
const ResetPassword = () => {
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");

  useEffect(() => {
    const checkForVerification = window.location.href.split("/");
    if (
      checkForVerification &&
      checkForVerification[checkForVerification.length - 1].includes(
        "email-verified",
      )
    ) {
      setResetPasswordMessage({
        message: "Email verified, please change your password.",
        type: "success",
      });
    }
  }, []);

  const [token] = window.location.pathname.split("/").splice(-1);
  console.log(token);
  const onFinish = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/password-reset/${token}`, values);
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    } catch (error) {
      console.log("error: ", error);
      setResetPasswordMessage({
        message: error?.response?.data?.message || error?.message,
        type: "error",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="log">
      <div className="card-wrapper">
        <Logo width={240} style={{ marginBottom: "40px" }} />
        <Card title="Reset password" className="login-header" bordered={false}>
          {resetPasswordMessage && (
            <AuthMessage content={resetPasswordMessage} />
          )}
          <p>Enter a new password below to change your password.</p>
          <Form
            {...layout}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="newPassword"
              hasFeedback
              rules={[
                { required: true, message: "Please enter your new password!" },
                {
                  validator: (_, value) => {
                    // Test if password is at least 12 characters long, contains one Upper case letter, one lower case letter, and one number.
                    if (
                      value.length >= 12 &&
                      /[A-Z]/.test(value) &&
                      /[a-z]/.test(value) &&
                      /[0-9]/.test(value)
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      "The password must contain at least 12 characters, at least one uppercase letter, and one number.",
                    );
                  },
                },
              ]}
            >
              <Input.Password
                placeholder="New Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!",
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm New Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className="login-button" type="primary" htmlType="submit">
                RESET PASSWORD
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      <Footer />
    </div>
  );
};
export default ResetPassword;
