import React, { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import HomebaseLicenseEntry from './HomebaseLicenseEntry';
import { HOMEBASE_STATS } from '../../config';

function HomebaseStatsListSimple({ group, data, loading, setLoading }) {
  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line no-unused-expressions
    data && Object.values(data).every((entry) => typeof entry !== 'undefined') && setLoading(false);
  }, [data, setLoading]);

  return (
    <div className="homebase__simple-list">
      {loading ? (
        <LoadingOutlined spin style={{ color: '#4962E2' }} />
      ) : (
        Object.entries(HOMEBASE_STATS)
          .filter(([entry]) => entry.group === group)
          .map(([name, entry], index) => {
            return (
              data &&
              (data[name] || data[name] === 0) &&
              data[name] !== undefined && (
                <HomebaseLicenseEntry key={index} entry={entry} name={name} data={data[name]} />
              )
            );
          })
      )}
    </div>
  );
}

export default HomebaseStatsListSimple;
