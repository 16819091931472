/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Input, Button, Form } from 'antd';

const formInit = {
  _id: null
};

const ClientBranchForm = ({
  isNew,
  isMaster,
  onSubmit,
  branch,
  parent,
  setEdit,
  level,
  level1,
  level2,
  level3
}) => {
  const [form] = Form.useForm();
  const initialValues = isNew ? { ...formInit } : { ...formInit, ...branch };
  useEffect(() => form.resetFields());
  const onFinish = async (values) => {
    setEdit({ visible: false });
    if (isMaster) onSubmit({ branchName: values.branchName, level: 1, parentBranch: null }, isNew);
    else if (isNew) onSubmit({ ...values, parentBranch: parent, level }, isNew);
    else onSubmit({ ...values, id: branch._id }, isNew);
  };

  return (
    <div className="client-branch-modal">
      <div className="panel-heading">
        <h4 className="panel-title">
          <span>
            {isNew ? 'Add' : 'Edit'} {level === 1 && level1}
            {level === 2 && level2}
            {level === 3 && level3}
          </span>
        </h4>
      </div>
      <Form
        className="form-horizontal"
        initialValues={initialValues}
        onFinish={(values) => onFinish(values)}
        layout="vertical"
        form={form}
      >
        <div>
          <Form.Item rules={[{ required: true, message: 'Please enter name!' }]} name="branchName">
            <Input placeholder={level === 1 ? level1 : level === 2 ? level2 : level3} />
          </Form.Item>

          <Form.Item hidden name="level">
            <Input placeholder="Level" />
          </Form.Item>
        </div>
        <div>
          <Button
            className="branch-form-button"
            type="primary"
            htmlType="submit"
            style={{ margin: '15px 0px', width: !isNew ? '100%' : 'auto' }}
          >
            {isNew ? 'Add' : 'Edit'} {level === 1 ? level1 : level === 2 ? level2 : level3}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ClientBranchForm;
