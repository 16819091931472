import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';

function HomebaseStatsEntry({ entry, data }) {
  return (
    <div className="homebase__simple-list-entry">
      <div className="homebase__simple-list-entry-wrapper">
        <div className="homebase__list-entry-icon">{entry.icon}</div>
        <span className="homebase__simple-list-entry-label">
          <span style={{ marginRight: '0.625rem' }}>{entry.label}</span>
          {entry.tooltip && (
            <Tooltip
              color="#4962E2"
              placement="bottom"
              title={
                <div
                  className="homebase__tooltip"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: entry.tooltip }}
                />
              }
            >
              <InfoIcon className="homebase__list-entry-info" />
            </Tooltip>
          )}
        </span>
      </div>
      <span className="homebase__simple-list-entry-value">{data}</span>
    </div>
  );
}

export default HomebaseStatsEntry;
