import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { notification, Layout, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import HeaderComponent from '../../components/base/HeaderComponent';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/Table';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'isMessage', 'isForm', 'createdAt', 'updatedAt'];

const QooTypes = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/qoo-types`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/qoo-types/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/qoo-types');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      if (item.isMessage) item.name = 'Message: ' + item.name;
      if (item.isForm) item.name = 'Form: ' + item.name;
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Qoo Types" />
        <div className="qoo-types-content">
          <div className="qoo-types-container">
            <div className="qoo-types-upper-part">
              <Link to="/new-qoo-type">
                <Button className="butty" type="primary">
                  Add new qoo type
                </Button>
              </Link>
            </div>
            <div
              className="qoo-types-table"
              style={{ textAlign: 'center', backgroundColor: 'white' }}
            >
              {data.isLoading && (
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              )}
              {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
                <Table
                  data={tableData}
                  deleteHandler={deleteDataHandler}
                  columnKeys={columnKeys}
                  title="Qoo types"
                  editPath="/edit-qoo-type/"
                />
              )}
              {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
                <h2>NO DATA</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QooTypes;
