import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CategoriesTable';
import HeaderComponent from '../../components/base/HeaderComponent';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt'];

const Categories = () => {
  const currentuser = useContext(UserContext);
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
  }, [fetchCategories]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/categories/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight'
      });
      history.push('/');
      history.push('/categories');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  let columnKeys;
  if (categories.data && categories.data.items && categories.data.items.length > 0) {
    const keys = Object.keys(categories.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (categories.data && categories.data.items && categories.data.items.length > 0) {
    tableData = categories.data.items.map((item) => {
      return item;
    });
  }

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Categories" />
        <div className="categories-content">
          <div className="categories-container">
            <div className="categories-upper-part">
              <Link to="/new-category">
                <Button className="butty" type="primary">
                  Add new category
                </Button>
              </Link>
            </div>

            <div
              className="categories-table"
              style={{ textAlign: 'center', backgroundColor: 'white' }}
            >
              {categories.isLoading && (
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              )}
              {!categories.isLoading &&
                categories.data &&
                categories.data.items &&
                categories.data.items.length > 0 && (
                  <Table
                    data={tableData}
                    deleteHandler={deleteDataHandler}
                    columnKeys={columnKeys}
                    title="Categories"
                    editPath="/edit-category/"
                  />
                )}
              {!categories.isLoading &&
                categories.data &&
                categories.data.items &&
                categories.data.items.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Categories;
