import React, { useState } from "react";
import { Form, Input, Button, Card } from "antd";
import Axios from "axios";
import { SERVER_URL } from "../config";
import AuthMessage from "../components/elements/AuthMessage";
import Footer from "../components/base/Footer";
import Logo from "../components/base/Logo";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const ForgotPassword = () => {
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState("");

  const onFinish = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/forgot-password`, values);
      setForgotPasswordMessage({
        message: "Reset password link is sent to your email",
        type: "success",
      });
    } catch (error) {
      setForgotPasswordMessage({
        message: error?.response?.data?.message,
        type: "error",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="log">
      <div className="card-wrapper">
        <Logo width={240} style={{ marginBottom: "40px" }} />
        <Card
          title="Forgot your password?"
          className="login-header"
          bordered={false}
        >
          {forgotPasswordMessage && (
            <AuthMessage content={forgotPasswordMessage} />
          )}
          <Form
            {...layout}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your Email!" },
                {
                  validator: (_, value) => {
                    if (value) {
                      const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
                      if (!reg.test(value))
                        return Promise.reject(
                          new Error("Please enter valid email address!"),
                        );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className="login-button" type="primary" htmlType="submit">
                SEND EMAIL
              </Button>
            </Form.Item>
          </Form>
          <div className="back-to-login">
            <a href="/">Back to Login</a>
          </div>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
