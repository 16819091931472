import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import {
  notification,
  Layout,
  Button,
  Input,
  Modal,
  Popconfirm,
  Empty,
  Table,
  Popover
} from 'antd';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import ContactForm from '../../components/forms/ContactForm';

const Contact = () => {
  const currentuser = useContext(UserContext);
  const [contacts, setContacts] = useState();
  const [contactForEdit, setContactForEdit] = useState(null);
  const [newContact, setNewContact] = useState(null);

  const getContacts = async (search = null) => {
    try {
      const response =
        search && search.length > 1
          ? await Axios.get(
              `${SERVER_URL}/contacts?filter=${JSON.stringify({
                $or: [
                  { firstName: { $regex: search, $options: 'i' } },
                  { lastName: { $regex: search, $options: 'i' } },
                  { email: { $regex: search, $options: 'i' } },
                  { phone: { $regex: search, $options: 'i' } }
                ]
              })}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` }
              }
            )
          : await Axios.get(`${SERVER_URL}/contacts`, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` }
            });
      if (response?.data?.items) setContacts(response.data.items);
      else setContacts([]);
    } catch (error) {
      console.log('Error getting contacts:', error?.response?.data?.message);
    }
  };

  const getContactForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/contacts/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data) setContactForEdit(response.data);
      else setContactForEdit(null);
    } catch (error) {
      console.log('Error getting contact for edit:', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getContacts();
  }, [setContacts, setContactForEdit, setNewContact]);

  const deleteContactHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/contacts/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Contact deleted successfully',
        placement: 'topLeft',
        duration: 2
      });
      getContacts();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const addAndEditContactHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/contacts` : `${SERVER_URL}/contacts/${contactForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: `Contact ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      setContactForEdit(null);
      setNewContact(null);
      getContacts();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/update.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const copyToClipboardHandler = async (link) => {
    await navigator.clipboard.writeText(link);
  };

  const handleSearch = (e) => getContacts(e);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    {
      title: 'Employer',
      dataIndex: 'employer'
    },
    {
      title: 'Type of HCP',
      dataIndex: 'typeHCP'
    },
    {
      title: 'Primary Speciality',
      dataIndex: ['primarySpeciality', 'name']
    },
    {
      title: 'Secondary Speciality',
      dataIndex: ['secondarySpeciality', 'name']
    },
    {
      title: 'URL',
      render: (text, record) => (
        <Popover
          content={
            record.contactURL && (
              <div>
                <div
                  style={{ cursor: 'pointer', margin: '5px 0px 15px 0px' }}
                  onClick={() => copyToClipboardHandler(record.contactURL)}
                >
                  {record?.contactURL}
                </div>
              </div>
            )
          }
          title="Contact URL"
        >
          {record.contactURL && (
            <p height="35px" width="35px">
              URL
            </p>
          )}
        </Popover>
      )
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getContactForEdit(record._id)}
              title="Edit brand"
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement="left"
              title="This will delete contact"
              onConfirm={() => deleteContactHandler(record._id)}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title="Delete contact" />
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Manage contacts" />
        <div className="teams-content">
          <div className="teams-container">
            <div className="teams-upper-part">
              <div className="left-part">
                <Input
                  className="inppy"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className="butty" type="primary" onClick={() => setNewContact(true)}>
                  Add Contact
                </Button>
              </div>
            </div>

            <div className="table-manage">
              <Table
                size="middle"
                bordered
                dataSource={contacts}
                columns={columns}
                locale={{ emptyText: <Empty description="No Contacts, please add a contact." /> }}
                rowKey="_id"
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false
                }}
              />
            </div>
          </div>
        </div>
        {contactForEdit && (
          <div>
            <Modal
              open={contactForEdit}
              onCancel={() => setContactForEdit(null)}
              footer={null}
              centered
            >
              <div style={{ padding: '8px' }}>
                <div>
                  <ContactForm
                    isNew={false}
                    data={contactForEdit}
                    onSubmit={addAndEditContactHandler}
                    SERVER_URL={SERVER_URL}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newContact && (
          <div>
            <Modal maskClosable={false} open={newContact} onCancel={() => setNewContact(null)} footer={null} centered>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <ContactForm
                    isNew
                    data={contactForEdit}
                    SERVER_URL={SERVER_URL}
                    onSubmit={addAndEditContactHandler}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Contact;
