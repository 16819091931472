import React from 'react';
import { Layout } from 'antd';
import UserActions from './UserActions';

const { Header } = Layout;

const HeaderComponent = ({ text }) => {
  return (
    <Header className="header-component">
      <h2 className="header-component__page-heading">{text}</h2>
      <UserActions />
    </Header>
  );
};

export default HeaderComponent;
