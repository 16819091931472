/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Fuse from 'fuse.js';
import {
  Button,
  Popconfirm,
  Table,
  Modal,
  Tag,
  Divider,
  Empty,
  Checkbox,
  Tabs,
  Upload
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import StepNavigation from './StepNavigation';
import ClientUserForm from '../../components/forms/ClientUserForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const groupUsersByBrand = (array) =>
  Object.values(
    array.reduce((a, item) => {
      const { email, brand } = item;
      if (!a[email]) a[email] = item;
      if (a[email].brand.filter((t) => t._id === brand[0]._id).length === 0)
        a[email].brand.push(brand[0]);
      return a;
    }, {})
  );

const SettingsStepUsers = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  addAndEditClientUserHandler,
  getUserForEdit,
  userForEdit,
  setUserForEdit,
  deleteClientUserHandler,
  brands,
  branches,
  client,
  users,
  bulkAddUsersHandler,
  validateBulkUploadData,
  downloadUserTemplateHandler
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [bulkUploadUserEmails, setBulkUploadUserEmails] = useState([]);
  const [uniqueUserEmails, setUniqueUserEmails] = useState([]);
  const [availableCreatorLicences, setAvailableCreatorLicences] = useState(0);
  const [availablePublisherLicences, setAvailablePublisherLicences] = useState(0);
  const [requiredCreatorLicences, setRequiredCreatorLicences] = useState(0);
  const [requiredPublisherLicences, setRequiredPublisherLicences] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setAvailableCreatorLicences(client?.totalCreatorLicences - client?.licencedCreators?.length);
    setAvailablePublisherLicences(
      client?.totalPublisherLicences - client?.licencedPublishers.length
    );
    setUniqueUserEmails(users.map((u) => u.email));
  }, [
    setBulkUploadData,
    setAvailableCreatorLicences,
    setAvailablePublisherLicences,
    setUniqueUserEmails,
    users,
    client,
    errors
  ]);

  const searchBrands = (term) => {
    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['name']
    };
    const fuse = new Fuse(brands, options);
    const found = fuse.search(term);
    return [found[0]?.item];
  };

  const searchBranches = (term) => {
    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['branchName']
    };
    const fuse = new Fuse(branches, options);
    const found = fuse.search(term);
    return found[0]?.item._id;
  };

  const uploadData = (file) => {
    const newBulkUploadData = [];
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line consistent-return
        const promises = rows.slice(1).map((r, index) => {
          if (r[0] && r[1] && r[2]) {
            return newBulkUploadData.push({
              key: index,
              email: r[1].trim(),
              firstName: r[2].trim(),
              lastName: r[3].trim(),
              role: r[4].trim(),
              brand: searchBrands(r[5].trim()),
              jobFunction: r[6].trim(),
              clientBranch: searchBranches(r[7].trim()),
              canCreateThemes: r[8].trim() === 'Yes',
              licenceActive: r[9].trim() === 'Yes',
              client: client?._id
            });
          }
        });
        await Promise.all(promises);
      });
      const group = groupUsersByBrand(newBulkUploadData);
      // Get number of creator licences required
      setRequiredCreatorLicences(
        group.filter((g) => (g.role === 'creator' || g.role === 'agency') && g.licenceActive).length
      );
      setRequiredPublisherLicences(
        group.filter((g) => g.role === 'publisher' && g.licenceActive).length
      );
      setBulkUploadUserEmails(group.map((g) => g.email));
      const validateData = await validateBulkUploadData(group);
      setErrors(validateData);
      resolve(setBulkUploadData(group));
    });
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text, record) =>
        record?.role?.map((role) => (
          <Tag style={{ backgroundColor: '#b3f5e1' }} key={role}>
            {role}
          </Tag>
        ))
    },
    {
      title: 'Brands',
      dataIndex: 'brand',
      render: (text, record) =>
        record?.brand?.map((brand) => (
          <Tag style={{ backgroundColor: '#e483f7' }} key={brand._id}>
            {brand.name}
          </Tag>
        ))
    },
    {
      title: 'Branch',
      dataIndex: ['clientBranch', 'branchName']
    },
    {
      title: 'License',
      dataIndex: 'licenceActive',
      render: (text, record) => <Checkbox disabled checked={record.licenceActive} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 60,
      render: (text, record) => record.status
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement="left"
              title="This will delete user"
              onConfirm={() => deleteClientUserHandler(record._id)}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title="Delete user" />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getUserForEdit(record._id)}
              title="Edit user"
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
      fixed: 'right'
    }
  ];

  return (
    <div className="company-settings-builder__step-1">
      <div className="company-settings-builder__left-side">
        <h2 className="company-settings-builder__step-subtitle">
          <span>Step 3: </span>
          <span>Add Users.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className="company-settings-builder__intro-text">
          Next, add Users that will be using the Qoolo app, and assign them roles and licenses. As
          with the Brands, you can choose to add the Users manually, or you can use our Bulk upload
          function by downloading and completing our template.
        </p>
        <div className="company-settings-builder__forms">
          <Tabs defaultActiveKey="1" centered className="company-settings__tabs">
            <Tabs.TabPane tab="Manual upload" key="1">
              {brands && brands.length > 0 && (
                <ClientUserForm
                  isNew
                  brands={brands}
                  branchs={branches}
                  createHandler={addAndEditClientUserHandler}
                  updateHandler={addAndEditClientUserHandler}
                  createProcess
                  availableCreatorLicences={availableCreatorLicences}
                  availablePublisherLicences={availablePublisherLicences}
                />
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab="Bulk upload" key="2">
              <p className="company-settings-builder__intro-text">
                Please download our preformatted template for uploading your User information{' '}
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={downloadUserTemplateHandler}
                >
                  here
                  <DownloadOutlined />
                </span>
                . There are additional guidelines for using the template included on the first tab.
                Once you have filled out the template, please upload the file.
              </p>
              <div className="bulk-upload-wrapper">
                <Upload
                  className="bulk-upload-teams"
                  style={{ margin: 0 }}
                  action={uploadData}
                  accept=".xlsx"
                  name="excelData"
                >
                  <Button style={{ margin: 0 }} type="primary">
                    {bulkUploadData && bulkUploadData.length === 0
                      ? 'Upload your completed template'
                      : 'Re-upload'}
                  </Button>
                </Upload>
              </div>
              {availableCreatorLicences < requiredCreatorLicences ||
              availablePublisherLicences < requiredPublisherLicences ? (
                <div className="company-settings-builder__licence-alert">
                  {/* Style this so if there are not enough specific licences they are highlighted in red. */}
                  <p
                    style={{
                      color: availableCreatorLicences >= requiredCreatorLicences ? 'green' : 'red'
                    }}
                  >
                    Remaining Creator licenses: {availableCreatorLicences}
                  </p>
                  <p
                    style={{
                      color: availableCreatorLicences >= requiredCreatorLicences ? 'green' : 'red'
                    }}
                  >
                    Required Creator licenses: {requiredCreatorLicences}
                  </p>
                  {availableCreatorLicences < requiredCreatorLicences && (
                    <p style={{ color: 'red' }}>Not enough Creator licenses available. &#9888; </p>
                  )}
                  <p
                    style={{
                      color:
                        availablePublisherLicences >= requiredPublisherLicences ? 'green' : 'red'
                    }}
                  >
                    Available Publisher licenses: {availablePublisherLicences}
                  </p>
                  <p
                    style={{
                      color:
                        availablePublisherLicences >= requiredPublisherLicences ? 'green' : 'red'
                    }}
                  >
                    Required Publisher licenses: {requiredPublisherLicences}
                  </p>
                  {availablePublisherLicences < requiredPublisherLicences && (
                    <p style={{ color: 'red' }}>Not enough Creator licenses available. &#9888; </p>
                  )}
                  <p style={{ color: 'blue' }}>
                    Please contact your account manager to purchase more licenses.
                  </p>
                </div>
              ) : (
                <div className="company-settings-builder__licence-ok">
                  You have enough available licenses &#x2713;
                </div>
              )}
              {errors && errors.existingUsersEmails && errors.existingUsersEmails.length > 0 ? (
                <div className="company-settings-builder__licence-alert">
                  <p style={{ color: 'red' }}>
                    Users with following emails are already registered: &#9888;
                  </p>
                  <ul>
                    {errors.existingUsersEmails.map((email) => (
                      <li key={email}>{email}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="company-settings-builder__licence-ok">
                  All emails are unique &#x2713;
                </div>
              )}
              {bulkUploadData && bulkUploadData.length > 0 && (
                <Button
                  className="bulk-upload-save-button"
                  disabled={errors?.creator || errors?.publisher || errors?.existingUsersEmails}
                  onClick={() => bulkAddUsersHandler(bulkUploadData)}
                >
                  Add Users
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="company-settings-builder__bottom-row-space-between">
          <Button className="company-settings-builder__step-btn" onClick={() => setStep(2)}>
            Previous step
          </Button>
          <Button
            id="company-settings_next-step"
            className="company-settings-builder__step-btn"
            type="primary"
            disabled={users.length === 0}
            onClick={() => {
              if (completedStep === 3) setCompletedStep(3);
              setStep(4);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type="vertical" style={{ height: 'auto' }} />

      <div className="table-manage">
        <Table
          size="middle"
          bordered
          dataSource={users}
          columns={columns}
          locale={{ emptyText: <Empty description="No Users, please add a user." /> }}
          rowKey="_id"
          pagination={{
            defaultPageSize: 10,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false
          }}
        />
      </div>

      {userForEdit && userForEdit !== null && brands && brands.length > 0 && (
        <div>
          <Modal open={userForEdit} onCancel={() => setUserForEdit(null)} footer={null} centered>
            <div style={{ padding: '8px' }}>
              <div>
                <ClientUserForm
                  isNew={false}
                  data={userForEdit}
                  branchs={branches}
                  brands={brands}
                  createHandler={addAndEditClientUserHandler}
                  updateHandler={addAndEditClientUserHandler}
                  availableCreatorLicences={availableCreatorLicences}
                  availablePublisherLicences={availablePublisherLicences}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SettingsStepUsers;
