import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {
  const renderProtectedRoute = (props) => {
    if (user && allowed.some((role) => user.role.includes(role))) {
      return <Component {...props} />;
    }
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={renderProtectedRoute} />;
};

export default ProtectedRoute;
